<template>
    <layout class="info-ma-wrapper">
        <div class="container">
            <!-- 面包屑 -->
            <div class="infomation-mobile-bread mt15 flex x-left">
                <div class="color999">您所在的位置：</div>
                <a-breadcrumb>
                    <a-breadcrumb-item>
                        <router-link class="color999" :to="{ path: '/special-ma/index/'+$route.params.id }">首页</router-link>
                    </a-breadcrumb-item>
                    <a-breadcrumb-item>热点资讯</a-breadcrumb-item>
                </a-breadcrumb>
            </div>
            <div class="flex infomation-centent mt20">
                <div class="infomation-list mt10">
                    <a-spin :spinning="loading" tip="Loading...">
                        <div class="meeting-report">
                            <div v-for="(item, index) in newList" :key="index" class="hot-topic-box"
                                :class="{ 'mt20': index != 0 }">
                                <hot-topic-item :path="'/special-ma/infomation-detail/'+$route.params.id" :type="item.type" :item="item" class="bb">
                                </hot-topic-item>
                            </div>
                            <a-empty v-if="!newList.length && !loading" />
                            <div class="t-c mt30" v-if="zixunCount > 0">
                                <a-pagination :total="zixunCount" v-model:current="params.page"
                                    :page-size="params.limit" :show-size-changer="false" show-quick-jumper
                                    :hideOnSinglePage="true" @change="handlePageChangeEvent($event)" />
                            </div>
                        </div>
                    </a-spin>
                </div>
                <div class="infomation-right">
                    <layout-right :config="layoutRightConfig"></layout-right>
                </div>
            </div>
        </div>
    </layout>
</template>

<script>
import layout from '@/components/layout/index-ma';
import layoutRight from '@/components/layout/layout-right';
import hotTopicItem from '@/components/hot-topic-item.vue';
import { committee_id } from '@/config';
export default {
    name: 'SpecialMaInfoList',
    components: {
        layout,
        layoutRight,
        hotTopicItem,
    },
    data() {
        return {
            loading: false,
            newList: [], //热点资讯列表
            params:{
                limit: 10, 
                topic_id: this.$route.params.id, 
                page: 1,
                topic_committee_id:committee_id
            },
            zixunCount:0, //资讯列表总是
            layoutRightConfig: {
                meeting: {
                    show: true, // 是否显示
                    title: '相关会议', // 标题
                    methods: 'get',
                    moreLink: '/special-ma/meeting-list/'+this.$route.params.id,
                    type: 'ma',
                    data: {
                        page: 1,
                        pageSize: 6,
                        topic_id: this.$route.params.id, 
                        topic_committee_id:committee_id
                    },   // 参数条件
                },
                // 相关视频
                video: {
                    show: true, // 是否显示
                    methods: 'get',
                    type: 'ma',
                    title: '相关视频', // 标题
                    moreLink: '/special-ma/video-list/'+this.$route.params.id,
                    data: {
                        page: 1,
                        PageSize: 4,
                        no_count: false,
                        topic_id: this.$route.params.id, 
                        topic_committee_id: committee_id
                    },   // 参数条件
                },
                // 相关指南
                guideDownload: {
                    show: true, // 是否显示
                    title: '相关指南', // 标题
                    type: 'ma',
                    methods: 'post',
                    moreLink: '/special-ma/guide-list/'+this.$route.params.id,
                    detailLink:'/special-ma/guide-detail/'+this.$route.params.id,
                    data: {
                        limit: 5, 
                        topic_id: this.$route.params.id, 
                        page: 1,
                        topic_committee_id:committee_id
                    },   // 参数条件
                },
            }
        }
    },
    computed: {

    },
    methods: {
        // 热点资讯列表
        getZixunList() {
            this.loading = true
            this.request.post('GetNews',this.params).then(res => {
                this.newList = res.data.list
                this.zixunCount = Number(res.data.count) || 0
                this.loading = false
            })
        },
        // 页码切换
        handlePageChangeEvent(page) {
            window.scrollTo(0,0);
            this.params.page = page
            this.getZixunList()
        },
    },
    created() {

    },
    mounted() {
        this.getZixunList()
    },

}
</script>
<style lang="less" scoped>
.info-ma-wrapper{
    .infomation-list{
        width: calc(66.66% - 30px);
        
        .hot-topic{
            width: 100%!important;
            border-bottom: 1px dashed #ddd;
        }
    }
    .infomation-right{
        width: 33.33%;
    }
}
@media screen and (max-width:768px) {
    .info-ma-wrapper{
        .container{
            padding: 0 10px;
            .infomation-mobile-bread{display: none;}
            .infomation-centent{
                display: block;
                .infomation-list{
                    width: 100%;
                }
                .infomation-right{
                    width: 100%;
                    padding-top: 20px;
                }
            }
        }
        
    }
}
</style>
